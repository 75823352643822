/* beth-ellen-regular - latin */
@font-face {
  font-family: 'Beth Ellen';
  font-style: normal;
  font-weight: 400;
  src: url('/_resources/themes/dakkapel/webfonts/bethellen/beth-ellen-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/_resources/themes/dakkapel/webfonts/bethellen/beth-ellen-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/_resources/themes/dakkapel/webfonts/bethellen/beth-ellen-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/_resources/themes/dakkapel/webfonts/bethellen/beth-ellen-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/_resources/themes/dakkapel/webfonts/bethellen/beth-ellen-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/_resources/themes/dakkapel/webfonts/bethellen/beth-ellen-v3-latin-regular.svg#BethEllen') format('svg'); /* Legacy iOS */
}
