//.list-image {
//  background-repeat: no-repeat;
//  background-position: 0 50%;
//  padding-left: 1rem;
//}

.bg-arrow-size {
  background-size: 14px 10px;
}

.bg-arrow-right, .bg-arrow-right-white {
  @apply bg-arrow-size;
}

.bottom-inner-shadow {
  top: 50%;
  @apply bg-gradient-to-t from-black/66 to-transparent bg-opacity-75;
}

.mirror {
  transform: scale(-1,1);
}

.ico-phone {
  @apply bg-no-repeat bg-phone-thin inline-block w-4 h-4 mr-1;
  background-size: contain;
}

.ico-mail {
  @apply bg-no-repeat bg-mail-thin inline-block w-4 h-4 mr-1;
  background-size: 16px 16px;
}

.bg-home {
  width: 3rem;

  &:hover,
  &.current {
    @apply bg-home-hover;
  }
}

.bg-image-left {
  @apply bg-no-repeat pl-10;
  background-position: 1rem 50%;
  //padding-left: 2.5rem;
  //background-repeat: no-repeat;
}

.bg-image-left-lg {
  @apply bg-image-left;
  padding-left: 3.5rem;
}

@for $i from 1 through 3 {
  .bg-ico-#{$i*16} {
    @apply bg-no-repeat overflow-hidden bg-center;
    background-size: #{$i*16}px #{$i*16}px;
    text-indent: -600rem;
    //background-position: center;
    //overflow: hidden;
    //background-repeat: no-repeat;
  }
}
