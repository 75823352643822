.btn-hover {
  @apply bg-corpblue text-white;
}

.btn {
  @apply p-4;
  &:hover {
    @apply btn-hover;
  }

  .prose & {
    text-decoration: none;
    font-size: theme('fontSize.base');

    &:hover {
      @apply btn-hover;
    }
  }
}

.btn-sm {
  @apply px-3 py-2;
  &:hover {
    @apply btn-hover;
  }
}

.btn-outline {
  @apply btn border border-black;
}

.btn-green {
  &, .prose & {
    @apply bg-green text-white;
  }
}

.btn-yellow {
  &, .prose & {
    @apply bg-yellow text-black;
  }
}

.btn-flamingo {
  &, .prose & {
    @apply bg-flamingo text-white;
  }
}

.btn-melon {
  &, .prose & {
    @apply bg-melon text-black;
  }
}

@variants group-hover {
  .btn-corpblue {
    @apply bg-corpblue text-white;
  }
}

.btn-arrow-right {
  @apply bg-image-left bg-arrow-size bg-arrow-right;

  &:hover {
    @apply bg-arrow-size bg-arrow-right-white;
  }
}

.btn-arrow-right-white {
  @apply bg-image-left bg-arrow-size bg-arrow-right-white;
}

.btn-arrow-left {
  @apply bg-image-left bg-arrow-size bg-arrow-left;

  &:hover {
    @apply bg-arrow-size bg-arrow-left-white;
  }
}

.btn-arrow-left-white {
  @apply bg-image-left bg-arrow-size bg-arrow-left-white;
}

.btn-configurator,
.btn-configurator-hover,
.btn-offerte,
.btn-support {
  @apply bg-image-left;
  background-size: 1rem auto;

  .buttons__holder & {
    @apply bg-no-repeat pl-4 pt-16 text-center;
    background-position: 50% 1.5rem;
    background-size: auto 1.4rem;

    @screen md {
      @apply bg-image-left pt-4 text-left;
      background-size: 1rem auto;
    }
  }
}


.btn-configurator-lg,
.btn-configurator-lg-hover,
.btn-offerte-lg,
.btn-support-lg {
  @apply bg-image-left-lg;
  background-size: 1.5rem auto;
}

.btn-configurator-hover {
  &, .btn-green & {
    @apply bg-tool-white;
  }
}

.btn-configurator, .btn-configurator-lg {
  @apply bg-tool-black;

  &:hover,
  .group:hover & {
    @apply bg-tool-white;
  }

  &.btn-green {
    @apply bg-tool-white;

    &:hover,
    .group:hover & {
      @apply bg-tool-white;
    }
  }
}

.btn-offerte, .btn-offerte-lg {
  @apply bg-offerte-black;
  &:hover,
  .group:hover & {
    @apply bg-offerte-white;
  }
}

.btn-support, .btn-support-lg {
  @apply bg-support-black;
  &:hover,
  .group:hover & {
    @apply bg-support-white;
  }

}
