@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin bgSizeHalf($width, $height) {
  background-size: $width/2 $height/2;
}

@mixin bgSizeHalfWidth($width) {
  background-size: $width/2 100%;
}

@mixin bgSizeHalfHeight($height) {
  background-size: 100% $height/2;
}

@mixin ralColours($originalImage, $default: false, $couldBeKeralit: false) {
  @if $default {
    background-image: url('/_resources/themes/dakkapel/images/configurator/#{$default}/' + $originalImage);
  }

  &.ral9001 {
    background-image: url('/_resources/themes/dakkapel/images/configurator/ral9001/' + $originalImage);
    @if ($couldBeKeralit) {
      &.material-keralit-horizontaal {
        background-image: url('/_resources/themes/dakkapel/images/configurator/ral9001/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-HOR'));
      }
      //&.material-keralit-verticaal {
      //  background-image: url('/_resources/themes/dakkapel/images/configurator/ral9001/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-VER'));
      //}
    }
  }
  &.ral7016 {
    background-image: url('/_resources/themes/dakkapel/images/configurator/ral7016/' + $originalImage);
    @if ($couldBeKeralit) {
      &.material-keralit-horizontaal {
        background-image: url('/_resources/themes/dakkapel/images/configurator/ral7016/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-HOR'));
      }
      //&.material-keralit-verticaal {
      //  background-image: url('/_resources/themes/dakkapel/images/configurator/ral7016/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-VER'));
      //}
    }
  }
  &.ral9016 {
    background-image: url('/_resources/themes/dakkapel/images/configurator/ral9016/' + $originalImage);
    @if ($couldBeKeralit) {
      &.material-keralit-horizontaal {
        background-image: url('/_resources/themes/dakkapel/images/configurator/ral9016/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-HOR'));
      }
      //&.material-keralit-verticaal {
      //  background-image: url('/_resources/themes/dakkapel/images/configurator/ral9016/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-VER'));
      //}
    }
  }
  &.ral7021 {
    background-image: url('/_resources/themes/dakkapel/images/configurator/ral7021/' + $originalImage);
    @if ($couldBeKeralit) {
      &.material-keralit-horizontaal {
        background-image: url('/_resources/themes/dakkapel/images/configurator/ral7021/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-HOR'));
      }
      //&.material-keralit-verticaal {
      //  background-image: url('/_resources/themes/dakkapel/images/configurator/ral7021/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-VER'));
      //}
    }
  }
  &.ral5011 {
    background-image: url('/_resources/themes/dakkapel/images/configurator/ral5011/' + $originalImage);
    @if ($couldBeKeralit) {
      &.material-keralit-horizontaal {
        background-image: url('/_resources/themes/dakkapel/images/configurator/ral5011/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-HOR'));
      }
      //&.material-keralit-verticaal {
      //  background-image: url('/_resources/themes/dakkapel/images/configurator/ral5011/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-VER'));
      //}
    }
  }
  &.ral6009 {
    background-image: url('/_resources/themes/dakkapel/images/configurator/ral6009/' + $originalImage);
    @if ($couldBeKeralit) {
      &.material-keralit-horizontaal {
        background-image: url('/_resources/themes/dakkapel/images/configurator/ral6009/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-HOR'));
      }
      //&.material-keralit-verticaal {
      //  background-image: url('/_resources/themes/dakkapel/images/configurator/ral6009/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-VER'));
      //}
    }
  }
  &.ralgrounded {
    background-image: url('/_resources/themes/dakkapel/images/configurator/grondverf/' + $originalImage);
    @if ($couldBeKeralit) {
      &.material-red-ceder-horizontaal {
        background-image: url('/_resources/themes/dakkapel/images/configurator/grondverf/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-HOR'));
      }
      //&.material-red-ceder-verticaal {
      //  background-image: url('/_resources/themes/dakkapel/images/configurator/grondverf/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-VER'));
      //}
    }
  }
  &.ralcustom {
    background-image: url('/_resources/themes/dakkapel/images/configurator/custom/' + $originalImage);
    @if ($couldBeKeralit) {
      &.material-keralit-horizontaal {
        background-image: url('/_resources/themes/dakkapel/images/configurator/custom/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-HOR'));
      }
      //&.material-keralit-verticaal {
      //  background-image: url('/_resources/themes/dakkapel/images/configurator/custom/' + str-replace($couldBeKeralit, '[VARIATION]', 'KER-VER'));
      //}
    }
  }
}

input.bg-on-checked {
  + label {
    @apply block bg-gray-300 py-2 px-3 border border-gray-300;
    &:hover {
      @apply border-yellow;
    }
  }

  &:checked {
    + label {
      @apply bg-white border-yellow py-2 px-3;
    }
  }
}

.ico-mode-default {
  @apply bg-no-repeat bg-mode-default inline-block w-4 h-4 mr-1 ;
  background-size: 12px 12px;

  &.selected {
    @apply bg-mode-default-selected;
  }
}

.ico-mode-extended {
  @apply bg-no-repeat bg-mode-extended inline-block w-4 h-4 mr-1 ;
  @include bgSizeHalf(33px, 30px);

  &.selected {
    @apply bg-mode-extended-selected;
  }
}

#previewConfig {
  div.cfg__element {
    @apply absolute bg-no-repeat;
  }

  .bg-shadow-left {
    background-size: 62px auto;
  }

  .bg-shadow-middle {
    background-size: 52px 50px;
  }

  .bg-shadow-right {
    background-size: 62px auto;
  }

  // raam
  // 0%   fffeff
  // 48%  c3e1f7
  // 100% fffeff

  #RAAMKLEUR {
    background-image: linear-gradient(0deg, #fffeff 0%, #c3e1f7 48%, #fffeff 100%);
  }

  .VAST-MT {
    @apply bg-repeat-x;
    //@include bgSizeHalf(918px, 188px);
    @include bgSizeHalfHeight(188px);
    @include ralColours('VAST-MT-repeat-x.png', false, 'VAST-MT-[VARIATION]-repeat-x.png');

    &.material-keralit-verticaal, &.material-keralit-horizontaal, &.material-red-ceder-horizontaal, &.material-red-ceder-verticaal {
      @include bgSizeHalf(918px, 188px);
    }
  }

  .VAST-M {
    @apply bg-repeat;
    //@include bgSizeHalf(918px, 1198px);
    @include bgSizeHalfHeight(1198px);
    @include ralColours('VAST-M-repeat.png', false, 'VAST-M-[VARIATION]-repeat.png');

    &.material-keralit-verticaal, &.material-keralit-horizontaal, &.material-red-ceder-horizontaal, &.material-red-ceder-verticaal {
      @include bgSizeHalf(1500px, 2200px);
    }
  }

  .VAST-RT {
    @include bgSizeHalf(22px, 198px);
    @include ralColours('VAST-RT-overlap.png', false, 'VAST-RT-[VARIATION]-overlap.png');
  }

  .VAST-RM {
    @include bgSizeHalf(22px, 1198px);
    @include ralColours('VAST-RM-overlap.png', false, 'VAST-RM-[VARIATION]-overlap.png');
  }

  .VAST-LT {
    @include bgSizeHalf(22px, 198px);
    @include ralColours('VAST-LT-overlap.png', false, 'VAST-LT-[VARIATION]-overlap.png');
  }

  .VAST-LM {
    @include bgSizeHalf(22px, 1198px);
    @include ralColours('VAST-LM-overlap.png', false, 'VAST-LM-[VARIATION]-overlap.png');
  }

  // replaced by vast-mt
  .BASIS-BOVENKANT {
    @apply bg-repeat-x;
    @include bgSizeHalf(160px, 36px);
    @include ralColours('BASIS-BOVENKANT-Repeat-x.png');
  }


  .BASIS-MT-L {
    @include bgSizeHalf(56px, 200px);
    //@include ralColours('BASIS-MT-L.png');
    @include ralColours('VAST-MT-repeat-x.png');
  }

  .BASIS-MT-R {
    @include bgSizeHalf(56px, 200px);
    //@include ralColours('BASIS-MT-R.png');
    @include ralColours('VAST-MT-repeat-x.png');
  }

  .BASIS-MT {
    @apply bg-repeat-x;
    @include bgSizeHalf(60px, 200px);
    //@include ralColours('BASIS-MT-REPEAT-X.png');
    @include ralColours('VAST-MT-repeat-x.png');
  }

  .BASIS-MB-L {
    @include bgSizeHalf(28px, 600px);
    @include ralColours('BASIS-MB-L.png');
  }

  .BASIS-MB-R {
    @include bgSizeHalf(28px, 600px);
    @include ralColours('BASIS-MB-R.png');
  }

  .BASIS-MB {
    @apply bg-repeat;
    @include bgSizeHalf(60px, 600px);
    @include ralColours('BASIS-MB-repeat-XY.png');
  }

  //#BASIS-LB {
  //  @apply bg-repeat-y;
  //  @include bgSizeHalf(123px, 600px);
  //  @include ralColours('BASIS-LB-repeat-Y.png');
  //}
  //
  //#BASIS-LT {
  //  @include bgSizeHalf(148px, 200px);
  //  @include ralColours('BASIS-LT.png');
  //}

  //#BASIS-RB {
  //  @apply bg-repeat-y;
  //  @include bgSizeHalf(123px, 600px);
  //  @include ralColours('BASIS-RB-repeat-Y.png');
  //}
  //
  //#BASIS-RT {
  //  @include bgSizeHalf(148px, 200px);
  //  @include ralColours('VAST-MT-repeat-x.png');
  //  //@include ralColours('BASIS-RT.png');
  //}

  /** ----- MINIROOFTOP ----*/
  #minirooftop {
    @apply bg-no-repeat bg-contain;
    width: 356px;
    height: 124px;
    top: -124px;
    left: 50%;
    transform: translateX(-50%);

    background-image: url('/_resources/themes/dakkapel/images/configurator/algemeen/minirooftop-center.png');
  }

  /** ----- BOEIBORD ----- */
  #BOEIBORD-CT {
    @apply bg-repeat-x;
    @include bgSizeHalf(666px, 24px);
    @include ralColours('BOEIBORD-CT.png');
  }

  #BOEIBORD-CB {
    @apply bg-repeat-x;
    @include bgSizeHalf(666px, 31px);
    @include ralColours('BOEIBORD-CB.png');
  }

  #BOEIBORD-CM {
    @apply bg-repeat-x;
    //@include bgSizeHalf(666px, 67px);
    @include bgSizeHalfWidth(666px);
    @include ralColours('BOEIBORD-CM.png');
  }


  #BOEIBORD-LM {
    //@include bgSizeHalf(333px, 67px);
    @include bgSizeHalfWidth(333px);
    @include ralColours('BOEIBORD-LM.png');
  }

  #BOEIBORD-RM {
    //@include bgSizeHalf(333px, 67px);
    @include bgSizeHalfWidth(333px);
    @include ralColours('BOEIBORD-RM.png');
  }

  #BOEIBORD-LB {
    @include bgSizeHalf(332px, 59px);
    @include ralColours('BOEIBORD-LB.png');
  }

  #BOEIBORD-LT {
    @include bgSizeHalf(332px, 24px);
    @include ralColours('BOEIBORD-LT.png');
  }

  #BOEIBORD-RB {
    @include bgSizeHalf(332px, 59px);
    @include ralColours('BOEIBORD-RB.png');
  }

  #BOEIBORD-RT {
    @include bgSizeHalf(332px, 24px);
    @include ralColours('BOEIBORD-RT.png');
  }

  #DAKKRAAL-KLEUR-L {
    @include bgSizeHalf(103px, 24px);
    @include ralColours('DAKKRAAL-zink-L.png', 'algemeen');
    &.aluxl {
      @include bgSizeHalf(103px, 32px);
      @include ralColours('KRAAL_XL-ALU-L.png', 'algemeen');
    }
  }

  #DAKKRAAL-KLEUR-M {
    @include bgSizeHalf(400%, 24px);
    @include ralColours('DAKKRAAL-zink-M.png', 'algemeen');
    &.aluxl {
      @include bgSizeHalf(400%, 32px);
      @include ralColours('KRAAL_XL-ALU-M.png', 'algemeen');
    }
  }

  #DAKKRAAL-KLEUR-R {
    @include bgSizeHalf(103px, 24px);
    @include ralColours('DAKKRAAL-zink-R.png', 'algemeen');
    &.aluxl {
      @include bgSizeHalf(103px, 32px);
      @include ralColours('KRAAL_XL-ALU-R.png', 'algemeen');
    }
  }

  #DAKLIJST-KLEUR-L {
    @include bgSizeHalf(91px, 32px);
    @include ralColours('DAKLIJST-ALU-L.png', 'algemeen');

    &.incolor {
      @include ralColours('DAKLIJST-KLEUR-L.png');
    }
  }

  #DAKLIJST-KLEUR-M {
    @apply bg-repeat-x;
    //@include bgSizeHalf(1860px, 32px);
    @include bgSizeHalfHeight(32px);
    @include ralColours('DAKLIJST-ALU_LONG-M.png', 'algemeen');
    &.incolor {
      @include ralColours('DAKLIJST-KLEUR-M.png');
    }
  }

  #DAKLIJST-KLEUR-R {
    @include bgSizeHalf(91px, 32px);
    @include ralColours('DAKLIJST-ALU-R.png', 'algemeen');
    &.incolor {
      @include ralColours('DAKLIJST-KLEUR-R.png');
    }
  }

  .KOZIJN-AANSLUIT-L-LM-repeat-schale-Y {
    @apply bg-repeat-y;
    @include bgSizeHalf(29px, 456px);
    @include ralColours('KOZIJN-AANSLUIT-L-LM-repeat-schale-Y.png');
  }

  .KOZIJN-AANSLUIT-LB {
    @include bgSizeHalf(156px, 200px);
    @include ralColours('KOZIJN-AANSLUIT-LB.png');
  }

  .KOZIJN-AANSLUIT-LT {
    @include bgSizeHalf(156px, 200px);
    @include ralColours('KOZIJN-AANSLUIT-LT.png');
  }

  .KOZIJN-MB {
    @apply bg-repeat-x;
    @include bgSizeHalf(168px, 64px);
    @include ralColours('KOZIJN-MB-repeatx.png');
    height: 32px;
    left: 58px;
    bottom: 0;
    width: calc(100% - 116px);

    &.frame-flat {
      height: 35px;
      @include bgSizeHalf(168px, 70px);
      @include ralColours('KOZIJN_VLAK-MB-repeatx.png');
    }

    &.previous-window, &.previous-tiltwindow, &.previous-fixedwindow {
      left: 32px;
      width: calc(100% - 90px);
    }
  }

  .KOZIJN-MT {
    @apply bg-repeat-x;
    @include bgSizeHalf(168px, 66px);
    @include ralColours('KOZIJN-MT-repeat-x.png');
    left: 58px;
    top: 17px;
    width: calc(100% - 116px);
    height: 33px;

    &.frame-flat {
      height: 35px;
      @include bgSizeHalf(168px, 70px);
      @include ralColours('KOZIJN_VLAK-MT-repeat-x.png');
    }

    &.previous-window, &.previous-tiltwindow, &.previous-fixedwindow {
      left: 32px;
      width: calc(100% - 90px);
    }

  }

  .KOZIJN-RM {
    @apply bg-repeat-y;
    //@include bgSizeHalf(74px, 456px);
    @include bgSizeHalfWidth(74px);
    @include ralColours('KOZIJN-RM-repeat-schale-Y.png');
    right: 0px;
    top: 115px;
    width: 37px;
    height: calc(100% - 213px);

    &.frame-flat {
      @include ralColours('KOZIJN_VLAK-RM-repeat-schale-Y.png');
    }
  }

  .KOZIJN-RT {
    @apply bg-right;
    @include bgSizeHalf(200px, 200px);
    @include ralColours('KOZIJN-RT.png');
    right: 0px;
    top: 17px;
    width: 60px;
    height: 100px;

    &.frame-flat {
      @include ralColours('KOZIJN_VLAK-RT.png');
    }
  }

  .KOZIJN-LT {
    @include bgSizeHalf(200px, 200px);
    @include ralColours('KOZIJN-LT.png');
    left: 0;
    top: 17px;
    width: 60px;
    height: 100px;

    &.frame-flat {
      @include ralColours('KOZIJN_VLAK-LT.png');
    }

    &.previous-window, &.previous-tiltwindow, &.previous-fixedwindow {
      background-position: -22px 0;
      left: -2px;
      width: 38px;
    }
  }

  .KOZIJN-LM {
    @apply bg-repeat-y;
    //@include bgSizeHalf(74px, 456px);
    @include bgSizeHalfWidth(74px);
    @include ralColours('KOZIJN-LM-repeat-schale-Y.png');
    left: 0;
    top: 115px;
    width: 37px;

    &.frame-flat {
      @include ralColours('KOZIJN_VLAK-LM-repeat-schale-Y.png');
    }

    &.previous-window, &.previous-tiltwindow, &.previous-fixedwindow {
      background-position: -22px 0;
      left: -2px;
      width: 38px;
    }
  }

  .KOZIJN-LB {
    @include bgSizeHalf(200px, 200px);
    @include ralColours('KOZIJN-LB.png');
    left: 0;
    bottom: 0;
    width: 60px;
    height: 100px;

    &.frame-flat {
      @include ralColours('KOZIJN_VLAK-LB.png');
    }

    &.previous-window, &.previous-tiltwindow, &.previous-fixedwindow {
      background-position: -22px 0;
      left: -2px;
      width: 38px;
    }
  }

  .KOZIJN-RB {
    @apply bg-right;
    @include bgSizeHalf(200px, 200px);
    @include ralColours('KOZIJN-RB.png');
    right: 0px;
    bottom: 0;
    width: 60px;
    height: 100px;

    &.frame-flat {
      @include ralColours('KOZIJN_VLAK-RB.png');
    }
  }

  .RAAM1-LT {
    @include bgSizeHalf(127px, 134px);
    @include ralColours('RAAM1-LT.png');
    left: 34px;
    top: 49px;
    width: 33px;
    height: 67px;

    &.previous-window, &.previous-tiltwindow, &.previous-fixedwindow {
      left: 10px;
    }
  }

  .RAAM1-LM {
    @apply bg-repeat-y;
    @include bgSizeHalf(40px, 456px);
    @include ralColours('RAAM-LM-repeat-schale-Y.png');
    left: 34px;
    top: 115px;
    width: 20px;
    height: calc(100% - 210px);

    &.previous-window, &.previous-tiltwindow, &.previous-fixedwindow {
      left: 10px;
    }
  }

  .RAAM1-LB {
    @include bgSizeHalf(128px, 132px);
    @include ralColours('RAAM1-LB.png');
    left: 34px;
    bottom: 31px;
    width: 33px;
    height: 66px;

    &.previous-window, &.previous-tiltwindow, &.previous-fixedwindow {
      left: 10px;
    }
  }

  .RAAM1-MT {
    @apply bg-repeat-x;
    @include bgSizeHalf(168px, 38px);
    @include ralColours('RAAM1-MT-repeat-x.png');
    left: 66px;
    top: 49px;
    right: 66px;
    height: 19px;

    &.previous-window, &.previous-tiltwindow, &.previous-fixedwindow {
      left: 42px;
    }
  }

  .RAAM1-MB {
    @apply bg-repeat-x;
    @include bgSizeHalf(168px, 38px);
    @include ralColours('RAAM1-MB-repeatx.png');
    left: 66px;
    bottom: 31px;
    right: 66px;
    height: 19px;

    &.previous-window, &.previous-tiltwindow, &.previous-fixedwindow {
      left: 42px;
    }
  }

  .RAAM1-RT {
    @include bgSizeHalf(126px, 134px);
    @include ralColours('RAAM1-RT.png');
    right: 34px;
    top: 49px;
    width: 33px;
    height: 67px;
  }

  .RAAM1-RM {
    @apply bg-repeat-y;
    @include bgSizeHalf(40px, 456px);
    @include ralColours('RAAM-RM-repeat-schale-Y.png');
    right: 34px;
    top: 115px;
    width: 20px;
    height: calc(100% - 210px);
  }

  .RAAM1-RB {
    @include bgSizeHalf(127px, 133px);
    @include ralColours('RAAM1-RB.png');
    right: 34px;
    bottom: 31px;
    width: 33px;
    height: 66px;
  }
}
