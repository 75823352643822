//@variants responsive {
//  .logo {
//    @apply absolute;
//  }
//}

#locationComplete {
  @apply bg-no-repeat bg-32 bg-left-top pl-8 bg-ico-search;

}

#mobileToggle {
  width: 36px;
  height: 18px;
}

.menu-bar-1, .menu-bar-2, .menu-bar-3 {
  @apply bg-green block absolute right-0 transition duration-300 ease-in-out;
  height: 2px;

  &:before {
    @apply block bg-green absolute overflow-hidden top-0;
    content: '';
    transform: skewX(-40deg);
    width: 5px;
    height: 2px;
  }
}

.menu-bar-1 {
  top: 0;
  width: 24px;

  &:before {
    right: calc(100% - 2px);
  }

  .menu--opened & {
    top: 7px;
    width: 32px;
    transform: rotate(135deg);

    &:before {
      display: none;
    }
  }
}

.menu-bar-2 {
  top: 8px;
  width: 28px;

  &:before {
    right: calc(100% - 2px);
  }

  .menu--opened & {
    @apply bg-white;
    opacity: 0;
    transform: translateX(-100vh);
  }
}

.menu-bar-3 {
  top: 16px;
  width: 32px;

  &:before {
    right: calc(100% - 2px);
  }

  .menu--opened & {
    width: 32px;
    top: 7px;
    transform: rotate(-135deg);

    &:before {
      display: none;
    }
  }
}

.header--schets {
  @apply bg-center-right bg-contain bg-no-repeat bg-schets-gespiegeld;
}

.header--skewed {
  &:before {
    @apply absolute left-0 w-full;
    content: '';
    top: -20px;
    height: 20px;
    background: linear-gradient(182deg, transparent 0%, transparent calc(50% - 1px), rgba(111, 190, 72, 1) 50%);
    //position: absolute;

    @screen md {
      @apply top-0 bottom-0 h-auto;
      background: linear-gradient(95deg, transparent 0%, transparent calc(50% - 1px), rgba(111, 190, 72, 1) 50%);
      left: -60px;
      width: 60px;
      //top: 0;
      //bottom: 0;
      //height: auto;
    }
  }

  &:after {
    @apply absolute w-full left-0;
    content: '';
    bottom: -20px;
    height: 20px;
    background: linear-gradient(182deg, rgba(111, 190, 72, 1) 0%, rgba(111, 190, 72, 1) calc(50% - 1px), transparent 50%);
    //position: absolute;
    //width: 100%;
    //left: 0;

    @screen md {
      @apply hidden;
      //display: none;
    }
  }
}

.bg-map-gradient {
  background: linear-gradient(180deg, theme('colors.green') 0%, theme('colors.green') 30px, theme('colors.mediumgreen') 30px);
}

.bg-gradient-tip-br {
  background-image: linear-gradient(125deg, var(--gradient-from-color) 0%, var(--gradient-from-color) calc(100% - 250px), var(--gradient-to-color) 100%);
}

.grecaptcha-badge {
  @apply mb-4;
}

.bg-offset {
  @apply bg-whitesmoke;
  .bg-whitesmoke & {
    @apply bg-white;
  }
}

.buttons__holder {
  + .dakkapel__placings {
    @apply pt-16;
    @screen md {
      @apply pt-24;
    }
  }
}

.dakkapel__steps {
  + .dakkapel__video {
    @apply pt-0;
  }
}

.dakkapel__row {
  &.bg-white + .dakkapel__row.bg-white:not(.dakkapel__examples) {
    @apply pt-0;
  }

  &.bg-whitesmoke + .dakkapel__row.bg-whitesmoke {
    @apply pt-0;
  }

  &.bg-corpblue + .dakkapel__row.bg-corpblue {
    @apply pt-0;
  }

  &.bg-green + .dakkapel__row.bg-green {
    @apply pt-0;
  }

  &.bg-mediumgreen + .dakkapel__row.bg-mediumgreen {
    @apply pt-0;
  }

  &.bg-yellow + .dakkapel__row.bg-yellow {
    @apply pt-0;
  }

  &.bg-melon + .dakkapel__row.bg-melon {
    @apply pt-0;
  }

  &.bg-flamingo + .dakkapel__row.bg-flamingo {
    @apply pt-0;
  }
}

.city__info {
  #sidebar__inner & {
    display: none;
  }
}

.invisible__text {
  @apply block overflow-hidden;
  text-indent: -99989px;
}

.close__button {
  @apply bg-close bg-contain w-8 h-8 overflow-hidden right-4 shadow-below;
  text-indent: -99989px;
  top: -1rem;
}

.close__popin {
  @apply bg-close-popin bg-contain w-10 h-10 overflow-hidden top-0 right-0;
  text-indent: -99989px;
}

#back-to-top {
  @apply bg-scrolltop bg-no-repeat bg-center;
  background-size: 20px 22px;
  text-indent: -9999px;
}

.grecaptcha-badge {
  bottom: 40px !important;
}

.project__contentpage {
  .project--inner {
    display: flex;
    align-items: flex-start;
    .project--details {
      flex-grow: 1;
      order: 1;
    }

    > img {
      order: 2;
      max-width: 400px;
      height: auto;
      object-fit: contain;
      margin: 0 0 20px 20px;
    }
  }
}

.w-sidebar {
  @apply w-full;
}

#sidebar__inner {
  .project--image {
    @apply w-full;

    @screen lg {
      width: calc(100% - 350px);
    }
  }

  .project--details {
    @apply w-full;

    @screen lg {
      width: 350px;
    }

  }
}
