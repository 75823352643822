@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@import "font-open-sans";
@import "font-bethellen";

@tailwind base;

@tailwind components;

@tailwind utilities;

// images used as backgrounds
@import "images";

// custom buttons
@import "buttons";

// specific styled blocks
@import "blocks";

// things like general arrows, toggles, accordions
@import "utilities";


// form styling
@import "form";

// custom design thingies, that cannot be achieved without custom css
@import "dakkapel-specifics";

// specific positioning for the configurator
@import "configurator-styling";
