.benefit__block {

  ul {
    @apply leading-relaxed;

    li {
      @apply bg-check bg-no-repeat pl-8;
      background-position: 0 6px;

      &:before {
        display: none; // disable prose
      }
    }

    span, a {
      @apply text-green;
    }
    a {
      @apply underline;
    }
  }
}

