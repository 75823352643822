/* GENERIC FORMS
----------------------------------------------- */
div.botty {
  @apply absolute;
  left: -6000px;
  top: -6000px;
}

::placeholder {
  @apply text-body italic;
}

.form__field {
  @apply border border-solid bg-white w-full px-4 py-3 leading-none;

  &:focus {
    outline: none;
  }

}

form {

  fieldset {
    // indicates a silverstripe form
    @apply border-0 m-0 p-0 text-body text-sm;

    // default forms are always rows
    div.field:not(.botty) {
      + .field {
        @apply mt-4;
      }
    }

    .field.nocaptcha {
      @apply mt-8 mb-8;
    }

    label {
      @apply text-sm;

      &.left {
        @apply block;
      }

      &.right {
        @apply text-xs;
      }
    }

    input[type=radio] + label {
      @apply text-sm;
    }

    span.description {
      @apply block text-xs italic font-normal;
    }

    input {
      &[type="text"],
      &[type="date"],
      &[type="time"],
      &[type="url"],
      &[type="password"],
      &[type="email"],
      &[type="number"],
      &[type="tel"] {
        @apply border rounded-none border-solid border-gray-400 bg-white w-full px-4 py-3 leading-none;

        &.error,
        &.holder-required {
          @apply border-flamingo;
        }
      }

      &:focus {
        @apply outline-none;
      }

      &[disabled] {
        @apply bg-whitesmoke text-gray-500 italic;
      }
    }

    textarea,
    select {
      @apply block border rounded-none border-solid border-gray-400 bg-white w-full px-4 py-3 leading-normal outline-none;

      &.error,
      &.holder-required {
        @apply border-flamingo;
      }
    }

    select {
      @apply border-gray-400 w-full;

      option.disabled {
        @apply text-gray-400;
      }
    }

    textarea {
      @apply resize-y;
    }

    /* Radio and Checkbox */
    .field {

      > label {
        @apply font-bold mb-2;
      }

      &.optionset {
        &.holder-required {
          > label {
            @apply font-bold;
          }

          .middleColumn {
            @apply border-flamingo p-2;
          }
        }

        .prose & {
          ul {
            @apply mb-0;
            li {
              @apply pl-0;
              &:before {
                @apply hidden;
              }
            }
          }
        }
      }

      &.checkbox, &.radio {
        @apply flex flex-wrap;
      }

      &.checkboxset {
        li {
          @apply flex flex-wrap items-center;
        }
      }

      &.checkbox {
        @apply items-center;
      }

      input.checkbox, input.radio {
        @apply w-4 h-4 mr-2 p-0 flex-shrink-0 flex-grow-0;
      }

      input.checkbox {
        @apply bg-white;
        //border-radius: 8px;
      }

      input[type=checkbox] + label {
        display: block;
        cursor: pointer;
        position: relative;
        height: 24px;
        line-height: 24px;
        //padding: 0 0 0 20px;
        margin: 0;
      }
    }

    .checkbox label.right,
    .radio label.right {
      display: inline-block;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    /* Messages */
    .message {
      display: block;
      width: 100%;
      background-color: #fef4ba;
      padding: 5px 10px;
      border: 1px solid #e8c805;

      .black & {
        color: #000;
      }
    }

    .holder-required {
      .message {
        border-top: 0;
        border-radius: 0 0 4px 4px;
      }

      &.checkbox {
        .message {
          margin-top: 10px;
        }
      }
    }

    .good {
      background-color: #ecf9d0;
      border-color: #8fbe00;
    }

    span.bad, span.required {
      @apply bg-flamingo bg-opacity-75 border-black text-white;
    }

    .dropzone-holder {
      @apply text-sm;
    }

    .dz-clickable {
      @apply text-corpblue;
    }
  }

  /* ACTIONS */
  input[type="submit"], .btn-toolbar .action {
    @apply rounded-none bg-green text-white font-bold inline-grid border border-solid border-green py-3 px-4 block w-full;

    &.btn-phone {
      @apply bg-yellow text-black text-sm;
      span {
        @apply flex items-center justify-center whitespace-no-wrap;
        &:before {
          @apply w-4 h-4 mr-2 inline-block bg-phone bg-no-repeat bg-center bg-contain;
          content: '';
        }
      }
    }
  }

  /* AREA SPECIFIC */
  /* LOGIN and FORGOT PASSWORD */
  #Remember {
    min-height: 20px;
  }

  #ForgotPassword {
    @apply text-xs mt-4;
  }

  .btn-toolbar {
    @apply items-center;
  }

  &:not(.form__inline) {
    .btn-toolbar {
      @apply mt-3 mb-3;
    }
  }

  &.form__inline {
    @apply flex flex-col;

    .btn-toolbar {
      @apply mt-4;
      @screen md {
        @apply mt-0 ml-4;
      }
    }

    fieldset, .btn-toolbar {
      display: flex;

      div.middleColumn,
      div.field {
        @apply h-full w-full;
        @screen md {
          @apply w-auto;
        }
      }
    }

    input {
      &[type="text"],
      &[type="date"],
      &[type="time"],
      &[type="url"],
      &[type="password"],
      &[type="email"],
      &[type="number"],
      &[type="tel"] {
        @apply h-full block;
      }
    }

    input[type="submit"], .btn-toolbar .action {
      @apply h-full block;
    }

    @screen md {
      @apply flex-row;
    }
  }

  &.hide__labels {
    div.field {
      > label {
        @apply hidden;
      }
    }
  }
}

div.form__row {
  @apply flex flex-wrap;

  > div.field {
    @apply float-none;
  }

  > div.holder,
  &:not(.form__row--simple) > div.field {
    @apply w-auto flex-1;
  }

  &.form__row--simple > div.field {
    @apply flex-1;
  }

  > .holder,
  > .field {
    transition: 0.4s ease border-left;
  }

  > label {
    @apply mb-0 pr-0 inline-flex items-center;
    //width: 170px;
    //min-width: 170px;
    //max-width: 170px;
  }

  div.checkbox {
    label {
      @apply block text-sm leading-loose;
    }
  }

  + .form__row {
    @apply mt-8;
  }

  &.form__row--simple {
    div.field {
      + .field {
        @apply mt-1;
        @screen md {
          @apply ml-2 mt-0;
        }
      }
    }
  }

  &.form__row--no-stack {
    div.field {
      + .field {
        @apply ml-2 mt-0;
      }
    }
  }

  // nested compositefield
  &.CompositeField {
    @apply flex flex-col;

    @screen md {
      @apply flex-row;
    }

    > div.field,
    > p.field,
    > a {
      @apply w-auto relative flex-grow;

      &.half {
        @screen md {
          @apply flex-grow-0;
          flex-basis: calc(50% - 1rem); // subtract the half of 2rem margin
        }
      }
    }

    > a {
      text-indent: 20px;
    }

    div.field {
      + .field {
        @screen md {
          @apply mt-0 ml-4;
        }
      }
    }
  }

}



