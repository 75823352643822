[v-cloak] {
  @apply hidden;
}

.navigation__link {
  @apply relative;
  //position: relative;

  &:hover,
  &.current {
    &:after {
      content: '';
      border-bottom: 3px solid;
      @apply border-green absolute bottom-0 right-4 left-4;
    }
  }
}

.navigation--mobile {
  @apply relative;

  &.current {
    .navigation__link--mobile {
      @apply text-white bg-green;
      &:hover {
        @apply text-black;
      }
    }
  }

  &.section {
    .navigation__sublink--mobile.current {
      @apply text-green font-bold;
      &:hover {
        @apply text-black;
      }
    }
  }

}

.hyperlink {
  @apply text-link;
  &:hover {
    @apply text-corpblue underline;
  }
}

/* ---- Arrow for toggles ---- */
.chevron {
  @apply block bg-no-repeat;
  width: 16px;
  height: 16px;
}

.chevron-down {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='white'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'/%3E%3C/svg%3E%0A");
  @apply chevron ;
}

.chevron-up {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='white'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 15l7-7 7 7' /%3E%3C/svg%3E");
  @apply chevron ;
}

.chevron-closed {
  @apply chevron-down;
  .opened & {
    @apply chevron-up;
  }
}

.li-simple-arrow,
ul.list-simple-arrows li {
  @apply relative;

  &:before {
    content: '› ';
  }
}

.li-check,
ul.list-checks li {
  @apply relative bg-check bg-no-repeat pl-8;
  background-position: 0 6px;
  background-size: 18px auto;

  .prose & {
    strong {
      @apply text-green;
    }

    &:before {
      display: none; // disable prose
    }
  }
}

// table auto markup
.table-striped {
  .prose & {
    td:first-child {
      padding-left: em(8, 14)
    }
  }

  tfoot,
  thead {
    tr {
      @apply bg-whitesmoke;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        @apply bg-lightsmoke;
      }

      &:nth-child(even) {
        @apply bg-whitesmoke;
      }
    }
  }

  tfoot {
    tr {
      @apply border-t-2 border-white;
    }
  }
}
